<template  lang="pug" >
    div(class="")
        v-row 
            v-col(cols="4")
                h2(class="text-h6  blue-500--text") Email
                p(class="text-body-2 mt-2 mb-0") Selecciona la estrategia de correo a utilizar
                div(class="")
                    v-radio-group(v-model="radioGroup")
                        v-radio(label="Ofertas" value="GO_TO_EMAIL_OFERTAS")
                        v-radio(label="Comercial" value="GO_TO_EMAIL_COMERCIAL")
                div 
                    v-divider(class="mb-5")
                    v-btn(color="default" x-small class="mr-3" @click="$emit('onCickSelectStep', STEPS.GO_TO_START)") Regresar
                    v-btn(color="primary" x-small @click="$emit('onCickSelectStep', radioGroup)") Continuar

</template>
<script>
import { STEPS } from './index.vue';
export default {
    name: 'MedioSelectedEmail',
    data() {
        return {
            radioGroup: 'GO_TO_EMAIL_OFERTAS',
            STEPS
        }
    },
    computed: {
    },
    methods: {
    },
}
</script>
